export const menuItemsData = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Admissions',
        url: '/admissions',
        submenu: [
            {
                title: 'Scholarships',
                url: '/scholarships'
            },
            {
                title: 'Basic Education',
                url: '/basic-education',
                downsubmenu: [
                    {
                        title: 'Pre-School & Grade School',
                        url: '/gs'
                    },
                    {
                        title: 'Junior High School',
                        url: '/jhs'
                    },
                    {
                        title: 'Senior High School',
                        url: '/shs'
                    },
                ]
            },
            {
                title: 'Higher Education',
                url: '/higher-education'
            },
            {
                title: 'Technical Education',
                url: '/technical-education'
            },
        ]
    },
    {
        title: 'Academics',
        url: '/academics',
        submenu: [
            {
                title: 'DYCIan Graduate Attributes',
                url: '/dycian-graduate-attributes'
            },
            {
                title: 'Undergraduate',
                url: '/undergraduate'
            }
        ]
    },
    {
        title: 'About',
        url: '/about',
        submenu: [
            {
                title: 'History',
                url: '/history'
            },
            {
                title: 'Vision and Mission',
                url: '/vision-mission'
            },
            {
                title: 'Core Values',
                url: '/core-values'
            },
            {
                title: 'The School Seal',
                url: '/dyci-seal'
            },
            {
                title: 'DYCIan News',
                url: '/newsletter'
            },
            {
                title: 'DYCI Robotics Team',
                url: '/dyci-robotics-team'
            }
        ]
    },
    {
        title: 'Contact Us',
        url: '/contact-us'
    },
]