import { Outlet } from "react-router-dom";

// import Header from "../components/Header";
import Navbar from "../components/Navbar";
import ProgressBar from 'react-scroll-progress-bar';
import BackToTop from "../components/BackToTop";
// import CookieConsent from "../components/CookieConsent";

export default function Root() {

    return (
        <div>
            <ProgressBar height="7" bgcolor="#c89b2c" duration="0.8"/>
            <div className="content-header">
                <Navbar/>
            </div>
            <div>
                <Outlet/>
            </div>
            <BackToTop/>
            {/* <CookieConsent/> */}
        </div>
    );
}