import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import Root from './routes/Root';
import ErrorPage from './error-page';
import reportWebVitals from './reportWebVitals';

// CSS files for styling
import './index.css';
import './App.css';

// Lazy load the route components
const App = React.lazy(() => import('./App'));
const Home = React.lazy(() => import('./routes/Home'));
const Admissions = React.lazy(() => import('./routes/Admissions'));
const About = React.lazy(() => import('./routes/About'));
const ContactUs = React.lazy(() => import('./routes/ContactUs'));
const Academics = React.lazy(() => import('./routes/Academics'));
const RoboticsTeam = React.lazy(() => import('./routes/RoboticsTeam'));
const DataPrivacy = React.lazy(() => import('./routes/DataPrivacy'));
const History = React.lazy(() => import('./routes/History'));
const DYCISeal = React.lazy(() => import('./routes/DYCISeal'));
const VisionMission = React.lazy(() => import('./routes/VisionMission'));
const CoreValues = React.lazy(() => import('./routes/CoreValues'));
const Newsletter = React.lazy(() => import('./routes/Newsletter'));
const NewsletterDetail = React.lazy(() => import('./components/NewsletterDetail'));
const Scholarships = React.lazy(() => import('./routes/Scholarships'));
const DYCIAttributes = React.lazy(() => import('./routes/DYCIAttributes'));
const GradeSchool = React.lazy(() => import('./routes/GradeSchool'));
const JuniorHighSchool = React.lazy(() => import('./routes/JuniorHighSchool'));
const SeniorHighSchool = React.lazy(() => import('./routes/SeniorHighSchool'));
const BasicEducation = React.lazy(() => import('./routes/BasicEducation'));

const LoadingSpinner = () => (
  <div className="fixed inset-0 flex items-center justify-center bg-gray-100 dark:bg-gray-900 animate-fade-in">
    <div
      className="inline-block h-12 w-12 animate-spin-smooth rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-gray-600 dark:text-white"
      role="status"
    >
      <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
        Loading...
      </span>
    </div>
  </div>

);

const router = createBrowserRouter([
  {
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <App />
          </Suspense>
        ),
      },
      {
        path: "home",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <Home />
          </Suspense>
        ),
      },
      {
        path: "admissions",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <Admissions />
          </Suspense>
        ),
      },
      {
        path: "about",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <About />
          </Suspense>
        ),
      },
      {
        path: "contact-us",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <ContactUs />
          </Suspense>
        ),
      },
      {
        path: "academics",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <Academics />
          </Suspense>
        ),
      },
      {
        path: "dyci-robotics-team",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <RoboticsTeam />
          </Suspense>
        ),
      },
      {
        path: "data-privacy-policy",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <DataPrivacy />
          </Suspense>
        ),
      },
      {
        path: "history",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <History />
          </Suspense>
        ),
      },
      {
        path: "scholarships",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <Scholarships />
          </Suspense>
        ),
      },
      {
        path: "dyci-seal",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <DYCISeal />
          </Suspense>
        ),
      },
      {
        path: "vision-mission",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <VisionMission />
          </Suspense>
        ),
      },
      {
        path: "core-values",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <CoreValues />
          </Suspense>
        ),
      },
      {
        path: "newsletter",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <Newsletter />
          </Suspense>
        ),
      },
      {
        path: "newsletter/:id",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <NewsletterDetail />
          </Suspense>
        ),
      },
      {
        path: "basic-education",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <BasicEducation />
          </Suspense>
        ),
      },
      {
        path: "dycian-graduate-attributes",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <DYCIAttributes />
          </Suspense>
        ),
      },
      {
        path: "gs",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <GradeSchool />
          </Suspense>
        ),
      },
      {
        path: "jhs",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <JuniorHighSchool />
          </Suspense>
        ),
      },
      {
        path: "shs",
        element: (
          <Suspense fallback={<LoadingSpinner />}>
            <SeniorHighSchool />
          </Suspense>
        ),
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

reportWebVitals();