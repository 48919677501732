import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import dyciLogoWText from "../assets/logo/dyci-logo-with-text-white.png";
import { menuItemsData } from "../assets/data/menuItemsData";

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [activeMenus, setActiveMenus] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleSubMenu = (menuKey) => {
    setActiveMenus((prev) => ({
      ...prev,
      [menuKey]: !prev[menuKey],
    }));
  };

  const handleNavigation = (url) => {
    navigate(url);
    setIsMenuOpen(false); // Close mobile menu after navigation
  };

  return (
    <nav
      className={`fixed top-0 w-full z-50 transition-all duration-300 ${
        isScrolled
          ? "bg-navy-blue text-white text-sm"
          : "bg-transparent text-white text-sm"
      }`}
    >
      <div className="container mx-auto flex items-center justify-between py-4 px-6 xs:py-2 md:px-1">
        {/* Logo */}
        <div className="h-12 flex items-center overflow-hidden">
          <Link to="/" className="flex items-center">
            <img
              src={dyciLogoWText}
              alt="DYCI Logo"
              className="w-44 xs:w-44 sm:w-44 md:w-44 lg:w-36 xl:w-44 object-contain"
            />
          </Link>
        </div>

        {/* Hamburger Menu for Mobile */}
        <button
          className="lg:hidden text-white focus:outline-none"
          onClick={toggleMenu}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>

        {/* Desktop Menu */}
        <ul className="justify-between items-center hidden lg:flex md:space-x-8">
          {menuItemsData.map((item, index) => (
            <li
              key={index}
              className="relative group"
              onMouseEnter={() => toggleSubMenu(index)}
              onMouseLeave={() => toggleSubMenu(index)}
            >
              <button
                onClick={() => handleNavigation(item.url)}
                className="navlink-btn px-2 py-2 flex items-center transition-all duration-200"
              >
                <span className="uppercase font-semibold text-xs flex items-center space-x-2">
                  {item.title}
                  {item.submenu && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      className="ml-1 shrink-0"
                      fill="currentColor"
                    >
                      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                    </svg>
                  )}
                </span>
              </button>
              {/* Submenu */}
              {item.submenu && activeMenus[index] && (
                <ul className="absolute left-0 top-full bg-white text-navy-blue shadow-lg mt-2 w-48">
                  {item.submenu.map((submenuItem, subIndex) => (
                    <li
                      key={subIndex}
                      className="relative group"
                      onMouseEnter={() => toggleSubMenu(`${index}-${subIndex}`)}
                      onMouseLeave={() => toggleSubMenu(`${index}-${subIndex}`)}
                    >
                      <button
                        onClick={() => handleNavigation(submenuItem.url)}
                        className="block px-4 py-2 text-left hover:bg-navy-blue hover:text-white transition-all duration-200 w-full"
                      >
                        <span className="flex items-center">
                          {submenuItem.title}
                          {submenuItem.downsubmenu && (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              height="24px"
                              viewBox="0 -960 960 960"
                              width="24px"
                              className="ml-2"
                              fill="currentColor"
                            >
                              <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
                            </svg>
                          )}
                        </span>
                      </button>

                      {/* Downsubmenu */}
                      {submenuItem.downsubmenu &&
                        activeMenus[`${index}-${subIndex}`] && (
                          <ul className="absolute left-full top-0 bg-white text-navy-blue shadow-lg mt-0 ml-2 w-48">
                            {submenuItem.downsubmenu.map((downsubmenuItem, downSubIndex) => (
                              <li key={downSubIndex}>
                                <button
                                  onClick={() => handleNavigation(downsubmenuItem.url)}
                                  className="block px-4 py-2 text-left hover:bg-navy-blue hover:text-white transition-all duration-200 w-full"
                                >
                                  {downsubmenuItem.title}
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                    </li>
                  ))}
                </ul>
              )}

            </li>
          ))}
        </ul>

        {/* Mobile Menu */}
        <ul
          className={`fixed lg:hidden top-0 left-0 h-screen w-64 bg-navy-blue text-white pt-10 flex flex-col transform ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300`}
        >
          <button
            className="absolute top-4 right-4 text-white"
            onClick={toggleMenu}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>

          {menuItemsData.map((item, index) => (
            <li key={index} className="w-full">
              <div
                className="flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-navy-blue-light"
                onClick={() => toggleSubMenu(index)}
              >
                <button
                  onClick={() => handleNavigation(item.url)}
                  className="block font-semibold uppercase"
                >
                  {item.title}
                </button>
                <span className="flex items-center">
                  {item.submenu && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      className="ml-2"
                      fill="currentColor"
                    >
                      <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                    </svg>
                  )}
                </span>
              </div>

              {item.submenu && activeMenus[index] && (
                <ul className="pl-6 bg-navy-blue-light">
                  {item.submenu.map((submenuItem, subIndex) => (
                    <li key={subIndex} className="w-full">
                      <div
                        className="flex justify-between items-center px-4 py-2 cursor-pointer hover:bg-navy-blue"
                        onClick={() =>
                          toggleSubMenu(`${index}-${subIndex}`)
                        }
                      >
                        <button
                          onClick={() => handleNavigation(submenuItem.url)}
                          className="block"
                        >
                          {submenuItem.title}
                        </button>
                        {submenuItem.downsubmenu && (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            height="24px"
                            viewBox="0 -960 960 960"
                            width="24px"
                            className="ml-2"
                            fill="currentColor"
                          >
                            <path d="M480-344 240-584l56-56 184 184 184-184 56 56-240 240Z" />
                          </svg>
                        )}
                      </div>

                      {submenuItem.downsubmenu &&
                        activeMenus[`${index}-${subIndex}`] && (
                          <ul className="pl-6 bg-navy-blue-dark">
                            {submenuItem.downsubmenu.map(
                              (downsubmenuItem, downSubIndex) => (
                                <li key={downSubIndex}>
                                  <button
                                    onClick={() =>
                                      handleNavigation(downsubmenuItem.url)
                                    }
                                    className="block px-4 py-2 hover:bg-navy-blue-light hover:text-white"
                                  >
                                    {downsubmenuItem.title}
                                  </button>
                                </li>
                              )
                            )}
                          </ul>
                        )}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;