import React from "react";
import { useRouteError, useNavigate } from "react-router-dom";
import Navbar from "./components/Navbar";
import dyciLogoWText from "./assets/logo/dyci-logo-with-text-white.png";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();

  if (process.env.NODE_ENV === "development") {
    console.error(error);
  }

  return (
    <div>
      <Navbar />
      <div className="bg-navy-blue text-gray-100 w-full h-screen flex flex-col items-center justify-center px-6">
        {/* Error Content */}
        <div className="text-center">
          <p className="text-6xl md:text-7xl font-bold tracking-wide text-gold">404</p>
          <p className="text-xl md:text-2xl font-semibold mt-2 text-white">Page Not Found</p>
          <p className="text-sm md:text-base text-white mt-4">
            Sorry, the page you are looking for could not be found.
          </p>
          <button
            onClick={() => navigate("/")}
            className="inline-flex items-center space-x-2 bg-gold hover:bg-opacity-80 text-white px-6 py-2 mt-6 rounded-md transition duration-150"
            title="Return Home"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="currentColor"
            >
              <path d="m313-440 224 224-57 56-320-320 320-320 57 56-224 224h487v80H313Z" />
            </svg>
            <span>Return Home</span>
          </button>
        </div>

        {/* Logo */}
        <div className="mt-8">
          <img src={dyciLogoWText} className="w-32 md:w-48" alt="DYCI Logo" />
        </div>
      </div>
    </div>
  );
}